import './App.css';
import TCMap from './components/TCMap';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { useState } from 'react';
import * as ELG from 'esri-leaflet-geocoder';
import { LocationSearch } from './components/LocationSearch';
import { InterconnectDiagrams } from './components/InterconnectDiagrams';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/map" exact component={TCMap} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

const Home = (props) => {

  const [locationError, setLocationError] = useState(
    ("geolocation" in navigator) ? null : "Please enable location services.");

  const onSuccess = (location) => {
    const {latitude, longitude} = location.coords;
    ELG.reverseGeocode().latlng([latitude, longitude]).run((error, result, response) => {
      props.history.push({
        pathname: '/map',
        searchData: {
          search: "Your Location",
          latitude,
          longitude,
          country: result?.address?.CountryCode || "USA"
        }
      });
    });
  };

  const handleGeoSubmit = (e) => {
    e.preventDefault()
    navigator.geolocation.getCurrentPosition(
      onSuccess, 
      () => setLocationError("Failed to find location.")
    );
  };

  return (
    <div className="col text-center homepage">
      <div className="header">
        <img src="TC_ENERGY_FULLCOLOUR_RGB_300DPI.png" className="logo" alt="TC Energy Logo" />
      </div>
      <div className="alert alert-light" >
      <h1>Considering an interconnection with TC&nbsp;Energy?</h1>
      <p>
        Click the <em>Find My Location</em> button or enter a location to see how close a TC Energy pipeline is to your project.
        <br/>From there you will be able to submit a request for a connection.
      </p>
      </div>
      {locationError &&
        <div className="alert alert-danger" id="alrt">{locationError}</div>
      }
      <button onClick={handleGeoSubmit} className="btn btn-success">Find My location</button>
      <h3>Or</h3>
      <LocationSearch onResult={r => {
        props.history.push({
          pathname: '/map',
          searchData: {
            search: r.text,
            latitude: r.latlng.lat,
            longitude: r.latlng.lng,
            country: r.properties?.Country || "USA"
          }
        });
      }}/>
      <SplashContent />
    </div>
  );

};

function SplashContent() {
  return <div id="splash">
    <h2>Interconnect types</h2>
    <InterconnectDiagrams />
  </div>;
}

export default App;
