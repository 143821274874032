/* A location search box with region restriction to North America
*/

import { useEffect, useState } from "react";
import * as ELG from 'esri-leaflet-geocoder';
import './LocationSearch.css';

const NORTH_AMERICA_BOUNDS = "-174,5,-49,75"; // used to restrict the active search area for locations

export function LocationSearch(props) {

  const { onResult } = props;

  const extent = NORTH_AMERICA_BOUNDS;
  const [query, _setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [arrowSelection, setArrowSelection] = useState(0); // 0 is no selection highlighted
  const [focused, setFocused] = useState(false);
  const [request, setRequest] = useState(null);

  const setQuery = (query) => {
     if(request) {
      request.abort()
      setRequest(null);
     }
    _setQuery(query);
  }

  function selectKey(key) {
    if(key == null) key = suggestions[0].magicKey;
    ELG.geocode().key(key).run((err, res) => {
      if(err) {
        /* ignore failed lookup */
      } else {
        if(onResult) onResult(res.results[0]);
      }
    })
  }

  useEffect(() => {
    if(query.length === 0) setSuggestions([]);
    else {
      let req = ELG.suggest()
        .text(query)
      req.params.searchExtent = extent; // using .bounds() has a bug
      let xhr = req.run((err, res) => {
        setRequest(null);
        if(res) {
          setSuggestions(res.suggestions.filter(s => !s.isCollection).map(({text, magicKey}) => ({text, magicKey})));
          setArrowSelection(0);
        }
      });
      setRequest(xhr);
    }
  }, [query, extent]);

  return <div className={"tc-location-search" + ((focused || query.length !== 0) ? " inuse" : "")}>
    <SearchInput {...{query, setQuery, selectKey, arrowSelection, setArrowSelection, suggestions, setFocused}}/>
    {focused && suggestions.length !== 0 && <SearchSuggestions {...{suggestions, selectKey, arrowSelection}} /> }
  </div>;

}

function SearchInput({query, setQuery, setFocused, arrowSelection, setArrowSelection, suggestions, selectKey}) {

  function keyDown(e) {
    switch(e.key) {
      case 'ArrowDown': setArrowSelection(Math.min(arrowSelection+1, suggestions.length-1)); break
      case 'ArrowUp': setArrowSelection(Math.max(0, arrowSelection-1)); break
      case 'Enter':
        let selection = suggestions[arrowSelection];
        if(selection) selectKey(selection.magicKey);
        break;
      default: return;
    }

    e.preventDefault();
  }

  return <input
    className="search-input"
    placeholder="Search for places or addresses"
    onKeyDown={e => keyDown(e)}
    onFocus={e => { setFocused(true); setArrowSelection(0); } }
    onBlur={e => setFocused(false)}
    onChange={(e) => setQuery(e.target.value)}
    value={query}
  />
}

function SearchSuggestions({suggestions, selectKey, arrowSelection}) {
  let selected = arrowSelection % suggestions.length;
  return <div className="suggestions">
    {suggestions.map((e,i) => <SearchSuggestion key={e.magicKey} selected={selected===i} selectKey={selectKey} {...e} />)}
  </div>;
}

function SearchSuggestion({text, magicKey, selectKey, selected}) {
  const click = e => {
    selectKey(magicKey);
  };
  const suppress = e => {
    e.stopPropagation();
    e.preventDefault();
  }
  return <div className={"suggestion" + (selected ? " selected" : "")} onMouseDown={suppress} onClick={click}>{text}</div>
}
