import abl from "./abl.json";
import ang from "./ang.json";
import anr from "./anr.json";
import alberta_ngtlauc__ercb from "./alberta_ngtlauc__ercb.json";
import bc_mainline from "./bc_mainline.json";
import bison_mainline from "./bison_mainline.json";
import cpg_central_kentucky from "./cpg_central_kentucky.json";
import cpg_columbia_gas from "./cpg_columbia_gas.json";
import cpg_columbia_gulf from "./cpg_columbia_gulf.json";
import cpg_crossroads from "./cpg_crossroads.json";
import cpg_hardy_storage from "./cpg_hardy_storage.json";
import cpg_millennium_pipeline from "./cpg_millennium_pipeline.json";
import fpl_alberta_east_leg from "./fpl_alberta_east_leg.json";
import fpl_alberta_west_leg from "./fpl_alberta_west_leg.json";
import fpl_saskatchewan from "./fpl_saskatchewan.json";
import fpl_south_bc from "./fpl_south_bc.json";
import glc from "./glc.json";
import glgt from "./glgt.json";
import gtn from "./gtn.json";
import nbaja from "./nbaja.json";
import nbpl from "./nbpl.json";
import ngtlneb from "./ngtlneb.json";
import pngts from "./pngts.json";
import sl2057 from "./sl2057.json";
import tcpleast from "./tcpleast.json";
import tqm from "./tqm.json";
import tuscarora from "./tuscarora.json";
export const GeoJSONs = { abl, ang, anr, alberta_ngtlauc__ercb, bc_mainline, bison_mainline, cpg_central_kentucky, cpg_columbia_gas, cpg_columbia_gulf, cpg_crossroads, cpg_hardy_storage, cpg_millennium_pipeline, fpl_alberta_east_leg, fpl_alberta_west_leg, fpl_saskatchewan, fpl_south_bc, glc, glgt, gtn, nbaja, nbpl, ngtlneb, pngts, sl2057, tcpleast, tqm, tuscarora };
export { abl, ang, anr, alberta_ngtlauc__ercb, bc_mainline, bison_mainline, cpg_central_kentucky, cpg_columbia_gas, cpg_columbia_gulf, cpg_crossroads, cpg_hardy_storage, cpg_millennium_pipeline, fpl_alberta_east_leg, fpl_alberta_west_leg, fpl_saskatchewan, fpl_south_bc, glc, glgt, gtn, nbaja, nbpl, ngtlneb, pngts, sl2057, tcpleast, tqm, tuscarora };
