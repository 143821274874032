import { CarouselProvider, Slider, Slide, Image, Dot } from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import './InterconnectDiagrams.css';
import scrollIntoView from 'smooth-scroll-into-view-if-needed'

const diagrams = [
  { title: "Direct", img: "interconnect_direct.png", alt: "direct interconnection diagram"},
  { title: "Lateral", img: "interconnect_lateral.png", alt: "lateral interconnection diagram"},
  { title: "Truck", img: "interconnect_truck.png", alt: "truck interconnection diagram"},
];

const AspectRatio = 2375 / 1082;

export function InterconnectDiagrams() {
  return <CarouselProvider
    onClick={scrollCarouselIntoView}
    className="tc-ic-diags"
    naturalSlideWidth={100}
    naturalSlideHeight={100 / AspectRatio}
    totalSlides={diagrams.length}>
      <div className="titledots" >
        {diagrams.map(({title}, index) => <Dot key={index} slide={index}>{title}</Dot>)}
      </div>
      <Slider>
        {diagrams.map(({title, img, alt}, index) => <Slide key={index} index={index}>
          <Image alt={alt} src={img} />
          </Slide>)}
      </Slider>
  </CarouselProvider>;
}

function scrollCarouselIntoView(e) {
  let node = e.target.closest(".tc-ic-diags");
  scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed' })
}