import { useMap, useMapEvent } from "react-leaflet";
import { systemInBounds, SystemColor, SystemLinks } from "./Pipelines";
import { useState } from "react";

/*
 * Maps system identifier to a display name.
 * Some smaller systems are not assigned names on the legend.
 * They will appear in the order listed here.
 */
const LegendEntries = {
  // us
  cpg_columbia_gas: "TCO",
  cpg_columbia_gulf: "CGT",
  cpg_crossroads: "Crossroads",
  bison_mainline: "Bison",
  cpg_hardy_storage: "Hardy",
  cpg_millennium_pipeline: "Millennium",
  nbaja: "North Baja",
  pngts: "PNGTS",
  nbpl: "NBPL",
  tuscarora: "Tuscarora",
  glgt: "GLGT",
  gtn: "GTN",
  anr: "ANR",
  //sl2057: "",
  // canada
  ngtlneb: "NGTL",
  tcpleast: "Canadian Mainline",
  abl: "Grand Rapids",
  fpl_alberta_east_leg: "Foothills",
  //alberta_ngtlauc__ercb: "",
  //ang: "",
  //bc_mainline: "",
  //fpl_alberta_west_leg: "Foothills Alberta West",
  //fpl_saskatchewan: "Foothilsl Saskatchewan",
  //fpl_south_bc: "Foothills South BC",
  //glc: "",
  tqm: "Trans-Quebec",
};

/* A legend which displays the pipelines that are currently within the map view bounds.
 */
export function Legend(props) {
  const map = useMap();
  const [bounds, setBounds] = useState(map.getBounds());
  useMapEvent("moveend", ({target:map}) => setBounds(map.getBounds()));

  return (
    <div className={"leaflet-top leaflet-left"}>
      <div className={("leaflet-control legend")}>
        <h4>Pipelines</h4>
        {Object.entries(LegendEntries)
          .filter(([key, label]) => systemInBounds(key, bounds))
          .map(([key, label]) => LegendEntry({key, label}))
        }
      </div>
    </div>
  );
}

function LegendEntry({key, label}) {
  const link = SystemLinks[key] || null;
  return <div className="legend-label" key={label}>
    {link ? <a href={link} target="_blank" rel="noreferrer">
        <i className="circle" style={{"background": SystemColor[key]}}></i>
        {label}
      </a>
    :
      <>
      <i className="circle" style={{"background": SystemColor[key]}}></i>
      {label}
      </>
    }
  </div>;
}