/*
 * Map-level metadata about pipelines.
 * The system names come from the exported files in /data/*.json
*/

import L from 'leaflet';
import { GeoJSONs } from '../data/all_systems';

const cpg_ebb = "https://ebb.tceconnects.com/infopost/";

export const SystemLinks = {
  anr: "http://ebb.anrpl.com/",
  glgt: "http://tcplus.com/Great Lakes",
  gtn: "http://tcplus.com/GTN",
  nbaja: "http://tcplus.com/North Baja",
  tuscarora: "http://tcplus.com/Tuscarora",

  cpg_columbia_gas: cpg_ebb,
  cpg_central_kentucky: cpg_ebb,
  cpg_crossroads: cpg_ebb,
  cpg_hardy_storage: cpg_ebb,
  cpg_millennium_pipeline: cpg_ebb,
  cpg_columbia_gulf: cpg_ebb,
  nbpl: cpg_ebb,
  pngts: cpg_ebb,
  bison_mainline: cpg_ebb,
};

/* Map color assignments for system drawings */
export const SystemColor = {
  abl: "#ce4040",
  alberta_ngtlauc__ercb: "#00ff22",
  ang: "#f0f",
  bc_mainline: "#2abd62",
  fpl_alberta_east_leg: "#cc48cc",
  fpl_alberta_west_leg: "#cc48cc",
  fpl_saskatchewan: "#cc48cc",
  fpl_south_bc: "#cc48cc",
  glc: "#aed8150ff",
  ngtlneb: "#0b86eb",
  sl2057: "#f0f",
  tcpleast: "#39a54b",
  tqm: "#909b2e",

  // US
  bison_mainline: "#0471a6",
  anr: "#ff7f11",
  cpg_central_kentucky: "#ac80a0",
  cpg_columbia_gas: "#ac80a0",
  cpg_columbia_gulf: "#89aae6",
  cpg_crossroads: "#3685b5",
  cpg_hardy_storage: "#061826",
  cpg_millennium_pipeline: "#92bfb1",
  glgt: "#b5bd89",
  gtn: "#729ea1",
  nbaja: "#f4ac45",
  nbpl: "#a61c3c",
  pngts: "#694a38",
  tuscarora: "#db5375",
};

/*
 * Categorization for restricting searches by country.
*/
export const SystemCountry = {
  abl: "CAN",
  alberta_ngtlauc__ercb: "CAN",
  ang: "CAN",
  bc_mainline: "CAN",
  fpl_alberta_east_leg: "CAN",
  fpl_alberta_west_leg: "CAN",
  fpl_saskatchewan: "CAN",
  fpl_south_bc: "CAN",
  glc: "CAN",
  ngtlneb: "CAN",
  sl2057: "CAN",
  tcpleast: "CAN",
  tqm: "CAN",

  // US
  bison_mainline: "USA",
  anr: "USA",
  cpg_central_kentucky: "USA",
  cpg_columbia_gas: "USA",
  cpg_columbia_gulf: "USA",
  cpg_crossroads: "USA",
  cpg_hardy_storage: "USA",
  cpg_millennium_pipeline: "USA",
  glgt: "USA",
  gtn: "USA",
  nbaja: "USA",
  nbpl: "USA",
  pngts: "USA",
  tuscarora: "USA",
};

/*
 * Returns the L.latLng of the nearest point on one of the geojson pipeline objects
 * and the name of the system it is on as an array.
 * The country parameter restricts the search to one jurisdiction.
 * It should be a three letter code eg. "USA" or "CAN"
*/
export function nearestPipeline(latLng, country) {
  let minDist = Infinity, minP1, minP2, minSystem;
  const maxZoom = 8;
  let p = L.CRS.EPSG3857.project(latLng, maxZoom);
  for(let name in GeoJSONs) {
    let geojson = GeoJSONs[name];
    if(country && SystemCountry[name] !== country) continue;
    for(let line of geojson.features[0].geometry.coordinates) {
      for(let i = 1; i < line.length; ++i) {
        let p1 = L.CRS.EPSG3857.project(L.latLng(line[i-1][1], line[i-1][0]), maxZoom);
        let p2 = L.CRS.EPSG3857.project(L.latLng(line[i][1], line[i][0]), maxZoom);
        let dist = L.LineUtil.pointToSegmentDistance(p, p1, p2);
        if(dist < minDist) {
          minDist = dist;
          minP1 = p1;
          minP2 = p2;
          minSystem = name;
        }
      }
    }
  }
  return [L.CRS.EPSG3857.unproject(L.LineUtil.closestPointOnSegment(p, minP1, minP2), maxZoom), minSystem];
}

/* Checks if the named system intersects a LatLngBounds.
 * Uses a bounding box check against each line segment.
*/
export function systemInBounds(name, bounds) {
  const
    lines = GeoJSONs[name].features[0].geometry.coordinates,
    l = bounds.getWest(),
    r = bounds.getEast(),
    b = bounds.getSouth(),
    t = bounds.getNorth();

  for(let line of lines) {
    for(let i = 1; i < line.length; ++i) {
      let [sx, sy] = line[i-1];
      let [ex, ey] = line[i];
      if(
        !(
          Math.min(sx, ex) > r ||
          Math.min(sy, ey) > t ||
          Math.max(sx, ex) < l ||
          Math.max(sy, ey) < b
        )
      ) return true;
    }
  }
  return false;
}

export { GeoJSONs };