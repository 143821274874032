import L from 'leaflet';
import { MapContainer, TileLayer, GeoJSON, Marker, Polyline } from 'react-leaflet';
import './TCMap.css';
import { SystemColor, nearestPipeline, GeoJSONs } from './Pipelines';
import { Legend } from './Legend';

const MileInMeters = 1609.344;
const DistanceThresholdTrucking = MileInMeters * 20;

const TCMap = props => {

  const query = props?.location?.searchData;

  if(!query) {
    window.location.href = "..";
    return null;
  }

  const
    country = ["USA", "CAN"].indexOf(query.country) === -1 ? "USA" : query.country,
    location = L.latLng(query.latitude, query.longitude),
    [nearest, ] = nearestPipeline(location, country),
    metric = country !== "USA",
    distance = location.distanceTo(nearest),
    distanceText = metric ? (distance / 1000).toFixed(2) + " km" : (distance / MileInMeters).toFixed(2) + " miles",
    mapBounds = new L.LatLngBounds(nearest, location).pad(0.3);

  return <>
    <div className="col text-center">
    <div className="header-map">
      <a href=".."><img src="TC_ENERGY_FULLCOLOUR_RGB_300DPI.png" className="logo" alt="TC Energy Logo" /></a>
      <div><a href="https://forms.office.com/Pages/ResponsePage.aspx?id=bOPOsFwiSE-2C5IfREtWmDBHdOBhxSJLgQzMv_ngUJtUNURUSURUT0pDM1NJRENOVU1DQU1YWFlMTC4u" className="btn btn-success" role="button" id="request">Request a project</a></div>
      <div className={"distance-result" + (distance != null ? " shown" : "")}>
        Distance: <span className={"distance"}>{distanceText}</span>
        {distance > DistanceThresholdTrucking &&
          <div className="trucking-prompt">Consider exploring trucking options in the <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=bOPOsFwiSE-2C5IfREtWmDBHdOBhxSJLgQzMv_ngUJtUNURUSURUT0pDM1NJRENOVU1DQU1YWFlMTC4u">request form</a>.</div>
        }
      </div>
    </div>
    <MapContainer bounds={mapBounds} boundsOptions={{maxZoom:13}} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <PipelineShapes />
      <Polyline positions={[location, nearest]} pathOptions={{ color: "red" }} />
      <Marker position={nearest} />
      <Marker position={location} />
      <Legend />
    </MapContainer>
    </div>
  </>;
}

function PipelineShapes() {
  return <>
  {
    Object.keys(GeoJSONs).map(name => 
      <GeoJSON key={name} data={GeoJSONs[name].features} style={{color:SystemColor[name], weight: 4}} />
    )
  }
  </>;
}

export default TCMap;
